import React from "react";
import { HeaderSection } from "../docs/sections";

/*
 * Files in this folder with default exports will show up as pages in the styleguide!
 */

export default (): JSX.Element => {
	return (
		<HeaderSection
			title={
				<>
					Fancy <span style={{ fontWeight: 400 }}>Design System</span>
				</>
			}
			subTitle="Fancy is Siteimprove's design system. We use it to design and build the front-end of our platform and products. It consists of design tokens, UI components, a Figma UI kit, and guidelines. Fancy accelerates product development and facilitates collaboration through a shared UI vocabulary. The end goal is a cohesive, accessible and intuitive user experience."
		/>
	);
};
